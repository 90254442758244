import { Icon } from './types'

type Props = Icon

export default ({
  fill = 'currentColor',
  size,
  height,
  width,
  svgProps,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || width || 24}
      height={size || height || 24}
      viewBox="0 0 24 24"
      {...svgProps}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        fill={fill}
        d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"
      />
    </svg>
  )
}
