import * as React from 'react'

// Components
import { LinkProps } from '../sites'
import MenuToggle from './MenuToggle'
import Navigation from './Navigation'
import useFixScroll from './useFixScroll'

/**
 * Inspired by here
 * https://www.framer.com/docs/examples/
 */

type Props = {
  links: LinkProps[]

  renderLink: (props: LinkProps) => React.ReactNode
}

const SideMenu = ({ links, renderLink }: Props) => {
  const [isOpen, setOpen] = React.useState(false)

  const handleToggle = React.useCallback(() => {
    setOpen(!isOpen)
  }, [isOpen])

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [])

  useFixScroll(isOpen)

  return (
    <div>
      <MenuToggle isOpen={isOpen} handleToggle={handleToggle} />
      <Navigation
        isOpen={isOpen}
        links={links}
        renderLink={renderLink}
        handleClose={handleClose}
      />
    </div>
  )
}

export default SideMenu
