import { Icon } from './types'

type Props = Icon

export default ({
  fill = 'currentColor',
  size,
  height,
  width,
  svgProps,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || width || 24}
      height={size || height || 24}
      viewBox="0 0 1644.89 529.14"
      {...svgProps}
    >
      <path
        fill={fill}
        d="M502.87,184.35h32.72q4.23-22.85,7-46.26l24.26,2.26q-2.82,22.85-6.21,44h53.59v24.26q-4.55,47.11-14.38,80.94a191.14,191.14,0,0,1-13,32.15q17.83,13.55,33.83,27.93l-14.1,21.15a304.29,304.29,0,0,0-32.44-27.64q-17.49,26-54.72,53.87l-19.74-22q33.28-23.12,51.9-47.95-20-13.54-44.28-27.92A851.33,851.33,0,0,0,531,208.6h-28.2Zm53.86,24.26q-7.61,42.59-17.49,80.67,13.54,8.18,25.67,16.64a3.57,3.57,0,0,0,.85-1.41Q582.4,273.2,588,208.61Zm64-59.51H753v26.51a387.74,387.74,0,0,1-47.38,53.87v23.41h55.28v26.23H705.63v90.54q-.57,10.43-5.36,16.08-7.05,8.18-16.64,8.18H636.28l-5.36-26.51q17.48,1.12,37.23,1.13,9,0,9-6.77V279.12h-62.9V252.89h62.9V220.72q20.31-15.51,43.72-45.13H620.73Z"
      />
      <path
        fill={fill}
        d="M835.41,206.63h187.32v23.09H991.14q30.46,27.64,51.62,55L1025,302.21a216.17,216.17,0,0,0-16.92-22q-27.65,3.11-68,5.92v26.23h101.54v24.26H940v25.1h113.68v23.13H802.41V361.72H913.82v-25.1H815.67V312.36h98.15V287.54q-40.34,2-90.54,3.67l-6.49-25.1q19.74,0,37.79-.28a162.82,162.82,0,0,0,29.33-36.1H835.4Zm100.41-70.51a252.63,252.63,0,0,1,9.59,25.1h108.32v57.5h-26.51V185.44H830v33.28H803.54v-57.5H916.92A215.24,215.24,0,0,0,907,140.35ZM990,260.22q-8.75-9-18.9-18.33l12.66-12.17h-71q-8.75,17.21-24.82,35.54,61.44-1.09,102.06-5Z"
      />
      <path
        fill={fill}
        d="M1101.95,149.09h244.54V175.6H1246.35v71.64h83.77v26.48h-83.77v83.77H1351v26.23H1095.18V357.49h37.55V217.59h28.77v139.9h55V175.61H1101.95Z"
      />
      <path
        fill={fill}
        d="M1554.63,225.72v30.74h90.26v23.13h-90.26v28.77h78.1V402h-26.51V389.87H1479.61V402H1453.1V308.36h76.15V279.59h-88.52q-1.41,20.31-4.51,41.46A378.06,378.06,0,0,1,1412.53,402l-22-20a251.27,251.27,0,0,0,21.72-68.26,566.33,566.33,0,0,0,4.51-68.26V153h219.43v72.73Zm-112.54,0v19.74q0,5.64-.28,11h87.43V225.72Zm168.64-48.47H1442.1v24.26h168.63ZM1479.58,366.79h126.64V331.53H1479.61Z"
      />
      <path
        fill={fill}
        d="M562,524.6h-9.75V483H523.49V524.6h-9.76V440.72h9.75v33.72H552.2V440.72h9.75Z"
      />
      <path
        fill={fill}
        d="M629.18,461.15q12.79,0,20.34,8.92t7.55,23.49q0,14.69-7.55,23.67t-20.34,9q-12.84,0-20.37-9t-7.52-23.7q0-14.63,7.55-23.52T629.18,461.15Zm0,57A15.31,15.31,0,0,0,642.5,511q4.87-7.14,4.88-17.3,0-10.71-4.82-17.6A16.49,16.49,0,0,0,615.8,476Q611,482.83,611,493.71q0,10.35,4.82,17.39a15.27,15.27,0,0,0,13.32,7Z"
      />
      <path
        fill={fill}
        d="M744.44,524.6h-9.28v-9.16q-10,10.65-21,10.64-17.42,0-17.42-21.11V462.63H706V502.3q0,7.91,2.5,11.8t8.44,3.89q8.5,0,18.26-10.58V462.63h9.28Z"
      />
      <path
        fill={fill}
        d="M829.43,471.5a47.23,47.23,0,0,0-14.69-2.56q-7.55,0-12,2.53t-4.4,6.75a6.35,6.35,0,0,0,2,4.67q2,2,8.09,3.72l8.86,2.56a39,39,0,0,1,10,4.13,16.47,16.47,0,0,1,5.8,5.83,15.87,15.87,0,0,1,1.93,7.94,16.17,16.17,0,0,1-7.43,14q-7.43,5.14-18.91,5.14t-18.85-3.45l1.78-8a38.55,38.55,0,0,0,16.71,3.69q7.73,0,12.58-2.88t4.85-7.64a8.1,8.1,0,0,0-2.41-5.74q-2.41-2.53-11.33-5.26-7.2-2.2-10.59-3.3a22.09,22.09,0,0,1-6.3-3.24,14,14,0,0,1-5.95-11.77q0-8,7-12.72t18.61-4.7a62.5,62.5,0,0,1,16.65,2.56Z"
      />
      <path
        fill={fill}
        d="M929.1,493.92H886.58q0,10.53,6.1,17.36a19.27,19.27,0,0,0,15,6.84,54.32,54.32,0,0,0,17.42-3l1.61,7.43a46.36,46.36,0,0,1-19.15,3.69q-13.8,0-22.24-8.86t-8.44-23.91q0-14.75,7.58-23.52A24.7,24.7,0,0,1,904,461.18a22.91,22.91,0,0,1,17.9,8.15q7.19,8.15,7.2,21.7ZM919,486.37a17.49,17.49,0,0,0-4.52-12.22q-4.52-5-10.11-5a16.11,16.11,0,0,0-11.92,4.76,18.83,18.83,0,0,0-5.26,12.43Z"
      />
      <path
        fill={fill}
        d="M1113.12,519.37a39.2,39.2,0,0,1-22.9,6.84q-16.29,0-24.77-11.18T1057,482.68q0-20.46,8.21-32t25-11.57a47.57,47.57,0,0,1,12,1.55,29.49,29.49,0,0,1,9.57,4.1l-3.21,7.67a36.61,36.61,0,0,0-18.38-4.76q-11.59,0-17.25,8.41t-5.59,26.64q0,35,22.89,35,9.69,0,19.86-5.95Z"
      />
      <path
        fill={fill}
        d="M1204.35,518.66a37.61,37.61,0,0,1-22.78,7.55q-16.35,0-24.8-11.09t-8.44-32.4q0-20.63,8.18-32.11t25.07-11.48a47.57,47.57,0,0,1,12,1.55,29.49,29.49,0,0,1,9.57,4.1l-3.21,7.67a36.61,36.61,0,0,0-18.38-4.76q-11.54,0-17.22,8.44t-5.61,26.59q0,35,22.89,35,8.62,0,13-3.63V493.32h-15.1v-8.44h24.86Z"
      />
      <path fill={fill} d="M1384.38,524.6h-45.65V440.72h9.75v75.4h35.92Z" />
      <path
        fill={fill}
        d="M1476,521.81a43.84,43.84,0,0,1-18.37,4.28q-16.59,0-16.59-17.54V470.67h-11.89v-8H1441V445.72h9v16.95h21.05v8H1450v35.44q0,5.65,2.5,8.77a8.22,8.22,0,0,0,6.78,3.12,36,36,0,0,0,15-3.39Z"
      />
      <path
        fill={fill}
        d="M1566.49,524.6h-9.28v-8.88a22,22,0,0,1-8.18,7.55,22.65,22.65,0,0,1-11.21,3q-10.65,0-16.83-9.49t-6.18-24.05q0-13.62,6.45-22.57t17.1-8.95a22.44,22.44,0,0,1,18.85,9.51v-30h9.28Zm-9.28-19V479.85q-6.66-10.71-16.06-10.7-7.67,0-12.13,6.81t-4.46,17.22q0,10.7,4.19,17.69t11.51,7a14.94,14.94,0,0,0,7.08-1.9,22.76,22.76,0,0,0,6.72-5.71q3.15-3.79,3.16-4.63Z"
      />
      <path
        fill={fill}
        d="M1633.73,511a7.31,7.31,0,0,1,7.43,7.19q0,.12,0,.24a7.7,7.7,0,0,1-2.17,5.5,7,7,0,0,1-5.26,2.29,6.83,6.83,0,0,1-5.17-2.29,7.76,7.76,0,0,1-2.14-5.5,7.21,7.21,0,0,1,2.11-5.32A7.07,7.07,0,0,1,1633.73,511Z"
      />
      <polygon
        fill={fill}
        points="352.76 264.57 264.57 264.57 264.57 352.76 352.76 352.76 352.76 440.95 352.76 440.95 264.57 440.95 176.38 440.95 88.19 440.95 88.19 219.45 220.47 110.83 220.47 110.83 300.31 176.38 440.94 176.38 220.47 0 220.47 0 0 176.38 0 529.14 0 529.14 88.19 529.14 440.95 529.14 440.95 529.14 440.95 264.57 352.76 264.57"
      />
    </svg>
  )
}
