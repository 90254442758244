import {
  Container,
  Grid,
  Text,
  useTheme,
  Link,
  Avatar,
  Spacer,
} from '@nextui-org/react'
import {
  mapContactLinkProps,
  ContactItem,
  LinkProps,
  LinkOption,
  mapPageLinkProps,
  withEmptyName,
  PageItem,
  mapSnsLinkProps,
  SnsItem,
} from '../sites'
import {
  HouseCgIcon,
  EnvelopIcon,
  PinIcon,
  PhoneIcon,
  FacebookIcon,
  InstagramIcon,
  MessengerIcon,
} from '../Icons'

type Props = {
  // Render to render link element
  renderLink: (props: LinkProps) => React.ReactNode

  homeLinkOptions?: LinkOption
}

export default ({ renderLink, homeLinkOptions }: Props) => {
  const { theme } = useTheme()

  return (
    <>
      <Spacer y={10} />
      <footer>
        <Container
          md
          className="border-solid border-0 border-t pt-8 pb-12"
          style={{
            borderColor: theme?.colors.border.value,
          }}
        >
          <Grid.Container gap={2} justify="center">
            <Grid xs={12} sm={6} md={4}>
              <div>
                {mapPageLinkProps(
                  '',
                  {
                    [PageItem.Home]: homeLinkOptions,
                  },
                  {
                    [PageItem.Home]: (
                      <HouseCgIcon
                        width={100}
                        height={36}
                        fill={theme?.colors.text.value}
                      />
                    ),
                  },
                  [PageItem.Home]
                )
                  // Avoid rendering name beside top logo
                  .map(withEmptyName)
                  .map(renderLink)}

                <Text
                  size="1.125rem"
                  className="my-2"
                >{`好室正居 House CG Ltd. © ${new Date().getFullYear()}`}</Text>
                <div className="my-2 inline-flex items-center">
                  {mapSnsLinkProps({
                    [SnsItem.Facebook]: (
                      <Text
                        color="$gray800"
                        className="flex items-center mr-1 lg:mr-2"
                      >
                        <FacebookIcon size={28} />
                      </Text>
                    ),
                    [SnsItem.Instagram]: (
                      <Text
                        color="$gray800"
                        className="flex items-center mr-1 lg:mr-2"
                      >
                        <InstagramIcon size={28} />
                      </Text>
                    ),
                  })
                    .map(withEmptyName)
                    .map(renderLink)}
                </div>
              </div>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              md={4}
              className="border-solid border-0 md:border-t-0 md:border-l md:pl-4"
              style={{
                borderColor: theme?.colors.border.value,
              }}
            >
              <div>
                <Text size="1.25rem">聯絡我們</Text>

                {mapContactLinkProps({
                  [ContactItem.Messenger]: <MessengerIcon size={20} />,
                  [ContactItem.Phone]: <PhoneIcon size={20} />,
                  [ContactItem.Email]: <EnvelopIcon size={20} />,
                  [ContactItem.Address]: <PinIcon size={20} />,
                }).map(({ key, description, href, icon, targetBlank }) => (
                  <Link
                    key={key}
                    color="text"
                    href={href}
                    className="flex items-center my-1"
                    {...(targetBlank && {
                      target: '_blank',
                      rel: 'noopener',
                    })}
                  >
                    <Text className="flex">
                      <Avatar squared size="sm" icon={icon} className="mr-2" />
                      {description}
                    </Text>
                  </Link>
                ))}
              </div>
            </Grid>
          </Grid.Container>
        </Container>
      </footer>
    </>
  )
}
