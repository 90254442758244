const doc =
  typeof document === 'undefined'
    ? {
        body: {},
        addEventListener() {},
        removeEventListener() {},
        activeElement: {
          blur() {},
          nodeName: '',
        },
        querySelector() {
          return null
        },
        querySelectorAll() {
          return []
        },
        getElementById() {
          return null
        },
        createEvent() {
          return {
            initEvent() {},
          }
        },
        createElement() {
          return {
            children: [],
            childNodes: [],
            style: {},
            setAttribute() {},
            getElementsByTagName() {
              return []
            },
          }
        },
        location: {
          hash: '',
        },
        documentElement: {},
      }
    : document // eslint-disable-line

export default doc
