import type { GatsbyBrowser } from 'gatsby'
import Layout from './src/layouts'

import 'styles/global.css'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
}) => {
  return <Layout>{element}</Layout>
}
