import React from 'react'

export type Item = PageItem | SnsItem | ContactItem

export enum PageItem {
  Home = 'Home',
  About = 'About',
  Team = 'Team',
  Cases = 'Cases',
}

export enum SnsItem {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
}

export enum ContactItem {
  Messenger = 'Messenger',
  Phone = 'Phone',
  Email = 'Email',
  Address = 'Address',
}

export type LinkProps = {
  key: string
  name: string
  description?: string
  href: string
  active: boolean
  external: boolean
  targetBlank: boolean
  routeKey?: string
  icon?: React.ReactNode
}

export const pageLinks: { [key in PageItem]: LinkProps } = {
  [PageItem.Home]: {
    key: PageItem.Home,
    name: '首頁',
    href: '/',
    external: false,
    targetBlank: false,
    routeKey: '/',
    active: false,
  },
  [PageItem.About]: {
    key: PageItem.About,
    name: '關於好室',
    href: '/about',
    external: false,
    targetBlank: false,
    routeKey: '/about',
    active: false,
  },
  [PageItem.Team]: {
    key: PageItem.Team,
    name: '好室團隊',
    href: '/team',
    external: false,
    targetBlank: false,
    routeKey: '/team',
    active: false,
  },
  [PageItem.Cases]: {
    key: PageItem.Cases,
    name: '精選改造案例',
    href: '/',
    external: false,
    targetBlank: false,
    routeKey: '/',
    active: false,
  },
}

export const snsLinks: { [key in SnsItem]: LinkProps } = {
  [SnsItem.Facebook]: {
    key: SnsItem.Facebook,
    name: 'Facebook 粉絲專頁',
    href: 'https://www.facebook.com/twhouse.cg',
    active: false,
    external: true,
    targetBlank: true,
  },
  [SnsItem.Instagram]: {
    key: SnsItem.Instagram,
    name: 'Instagram 粉絲專頁',
    href: 'https://www.instagram.com/house_cg2020/',
    active: false,
    external: true,
    targetBlank: true,
  },
}

export const contactLinks: { [key in ContactItem]: LinkProps } = {
  // See more detail here:
  // https://developers.facebook.com/docs/messenger-platform/discovery/m-me-links/
  [ContactItem.Messenger]: {
    key: ContactItem.Messenger,
    name: 'Facebook Messenger',
    description: 'Facebook Messenger',
    href: 'https://m.me/house.cg.52',
    active: false,
    external: true,
    targetBlank: true,
  },
  [ContactItem.Phone]: {
    key: ContactItem.Phone,
    name: '電話',
    description: '02-23676063',
    href: 'tel:+886223676063',
    active: false,
    external: true,
    targetBlank: false,
  },
  [ContactItem.Email]: {
    key: ContactItem.Email,
    name: 'Email',
    description: 'service@house-cg.com.tw',
    href: 'mailto:service@house-cg.com.tw',
    active: false,
    external: true,
    targetBlank: false,
  },
  [ContactItem.Address]: {
    key: ContactItem.Address,
    name: '地址',
    description: '台北市大安區辛亥路一段 40 號 1F',
    href: 'https://goo.gl/maps/1nQRajxnGRt77rJt7',
    active: false,
    external: true,
    targetBlank: true,
  },
}

export type SiteMetaData = {
  name: {
    full: string
    en: string
    zh: string
  }
  title: string
  description: string
  keywords: string
  slogan: string
  figures: {
    landlords: number
    tenants: number
    cases: number
    rooms: number
  }
}
export const siteMetaData: SiteMetaData = {
  name: {
    full: '好室正居 House CG Ltd.',
    en: 'House CG Ltd.',
    zh: '好室正居',
  },
  title: '好室正居 House CG Ltd.',
  description:
    '好室正居 House CG Ltd.｜包租代管以及空間規劃管理｜家的好事，從這裡開始',
  keywords: '好室正居,好事正居,house-cg',
  slogan: '家的好事，從這裡開始',
  figures: {
    landlords: 50, // 合作過的屋主
    tenants: 700, // 承租房客
    cases: 70, // 成功改造案例
    rooms: 250, // 管理房間數
  },
}

export type LinkOption = {
  // If given, means it's an external link
  origin?: string
}

export type PageLinkOptions = { [key in PageItem]?: LinkOption }

export const mapPageLinkProps = (
  currentPath: string,
  opts: PageLinkOptions = {},
  icons: { [key in PageItem]?: React.ReactNode } = {},
  orders: PageItem[] = [
    PageItem.Home,
    PageItem.About,
    PageItem.Team,
    PageItem.Cases,
  ]
): LinkProps[] => {
  return orders.map((key) => {
    const value = { ...pageLinks[key] }
    const origin = opts[key]?.origin

    if (origin) {
      value.external = true
      value.href = new URL(value.href, origin).href
    }

    if (icons[key]) {
      value.icon = icons[key]
    }

    // Avoid external index page being active mistakenly
    if (value.routeKey === currentPath && !value.external) {
      value.active = true
    }

    return value
  })
}

export const mapSnsLinkProps = (
  icons: { [key in SnsItem]: React.ReactNode },
  orders: SnsItem[] = [SnsItem.Facebook, SnsItem.Instagram]
): LinkProps[] => {
  return orders.map((key) => {
    const value = { ...snsLinks[key] }

    value.icon = icons[key]

    return value
  })
}

export const mapContactLinkProps = (
  icons: { [key in ContactItem]: React.ReactNode },
  orders: ContactItem[] = [
    ContactItem.Messenger,
    ContactItem.Phone,
    ContactItem.Email,
    ContactItem.Address,
  ]
): LinkProps[] => {
  return orders.map((key) => {
    const value = { ...contactLinks[key] }

    value.icon = icons[key]

    return value
  })
}

export const withEmptyName = (lp: LinkProps): LinkProps => ({
  ...lp,
  name: '',
})

export const withEmptyIcon = (lp: LinkProps): LinkProps => ({
  ...lp,
  icon: null,
})
