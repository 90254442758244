module.exports = [{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":720,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"好室正居 House CG Ltd.｜包租代管以及空間規劃管理","short_name":"好室正居","icon":"content/assets/icon-maskable-512x512.png","icons":[{"src":"/icons-48x48.png","type":"image/png","sizes":"48x48","purpose":"any maskable"},{"src":"/icons-72x72.png","type":"image/png","sizes":"72x72","purpose":"any maskable"},{"src":"/icons-96x96.png","type":"image/png","sizes":"96x96","purpose":"any maskable"},{"src":"/icons-144x144.png","type":"image/png","sizes":"144x144","purpose":"any maskable"},{"src":"/icons-192x192.png","type":"image/png","sizes":"192x192","purpose":"any maskable"},{"src":"/icons-256x256.png","type":"image/png","sizes":"256x256","purpose":"any maskable"},{"src":"/icons-384x384.png","type":"image/png","sizes":"384x384","purpose":"any maskable"},{"src":"/icons-512x512.png","type":"image/png","sizes":"512x512","purpose":"any maskable"}],"start_url":"/","background_color":"#fafafa","theme_color":"#fafafa","display":"standalone","include_favicon":false,"theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"3ad0355286e0f725db95f65c4a7e0be8"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
