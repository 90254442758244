import classnames from 'classnames'
import { motion } from 'framer-motion'
import { Container, Switch, useTheme, ThemeType } from '@nextui-org/react'

import { MenuItem } from './MenuItem'
import { LinkProps } from '../sites'

const list = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const background = {
  open: {
    height: 'calc(100vh - 4rem)',
    display: 'block',
  },
  closed: {
    height: 0,
    display: 'none',
    transition: {
      delay: 0.1,
      when: 'afterChildren',
    },
  },
}

const itemIds = [0, 1, 2, 3, 4]

type Props = {
  isOpen: boolean
  links: LinkProps[]
  renderLink: (props: LinkProps) => React.ReactNode
  handleClose: () => void
}

export default ({ isOpen, links, renderLink, handleClose }: Props) => {
  const { theme } = useTheme()

  return (
    <motion.div
      className="absolute left-0 w-full top-16"
      style={{ background: theme?.colors.background.value }}
      initial="closed"
      variants={background}
      animate={isOpen ? 'open' : 'closed'}
    >
      <motion.ul variants={list}>
        {links.map((link) => (
          <MenuItem
            key={link.key}
            link={link}
            renderLink={renderLink}
            handleClose={handleClose}
          />
        ))}
      </motion.ul>
    </motion.div>
  )
}
