import { Icon } from './types'

type Props = Icon

export default ({
  fill = 'currentColor',
  size,
  height,
  width,
  svgProps,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || width || 24}
      height={size || height || 24}
      viewBox="0 0 24 24"
      {...svgProps}
    >
      <path
        fill={fill}
        d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z"
      ></path>
    </svg>
  )
}
