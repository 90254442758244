import { Avatar } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { LinkProps, withEmptyIcon } from '../sites'

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

type Props = {
  link: LinkProps
  renderLink: (props: LinkProps) => React.ReactNode
  handleClose: () => void
}

export const MenuItem = ({ link, renderLink, handleClose }: Props) => {
  return (
    <motion.li variants={variants}>
      <div className="inline-flex items-center my-2" onClick={handleClose}>
        <Avatar
          squared
          size="sm"
          color="primary"
          icon={link.icon}
          className="mr-3"
        />
        {
          // Avoid rendering duplicate icon
          renderLink(withEmptyIcon(link))
        }
      </div>
    </motion.li>
  )
}
