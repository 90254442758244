import { useEffect } from 'react'
import doc from './document'

export default (isOpen: boolean) => {
  const bodyNode = doc.querySelector('body')

  useEffect(() => {
    if (!bodyNode) return

    if (isOpen) {
      bodyNode.style.overflow = 'hidden'
    } else {
      bodyNode.style.overflow = ''
    }
  }, [isOpen, bodyNode])
}
