import { Container, Switch, useTheme, ThemeType, Text } from '@nextui-org/react'

// Components
import SideMenu from './SideMenu'
import {
  mapPageLinkProps,
  mapSnsLinkProps,
  LinkProps,
  PageLinkOptions,
  PageItem,
  SnsItem,
  withEmptyName,
} from '../sites'
import {
  MoonIcon,
  FaceIcon,
  HouseCgIcon,
  HomeIcon,
  CategoryAltIcon,
  SearchIcon,
  FacebookIcon,
  InstagramIcon,
} from '../Icons'

type Props = {
  setTheme: (theme: ThemeType) => void

  // Render to render link element
  renderLink: (props: LinkProps) => React.ReactNode

  // current path of the page
  currentPath: string
  pageLinkOptions?: PageLinkOptions
}

export default ({
  setTheme,
  renderLink,
  currentPath,
  pageLinkOptions = {},
}: Props) => {
  const { isDark, theme } = useTheme()

  return (
    <header className="sticky z-[10000] w-full top-0">
      <nav
        className="h-16 flex items-center border-0 border-b border-solid "
        style={{
          borderColor: theme?.colors.border.value,
          background: theme?.colors.background.value,
        }}
      >
        <Container lg display="flex" alignItems="center">
          <div className="mr-auto">
            {mapPageLinkProps(
              currentPath,
              pageLinkOptions,
              {
                [PageItem.Home]: (
                  <HouseCgIcon
                    width={100}
                    height={36}
                    fill={theme?.colors.text.value}
                  />
                ),
              },
              [PageItem.Home]
            )
              // Avoid rendering name beside top logo
              .map(withEmptyName)
              .map(renderLink)}
          </div>

          <div className="hidden md:flex md:items-center">
            <div
              className="md:inline-flex border-0 border-r border-solid pr-2"
              style={{
                borderColor: theme?.colors.border.value,
              }}
            >
              {mapPageLinkProps(currentPath, pageLinkOptions).map(
                (lp: LinkProps) => (
                  <div key={lp.key} className="mx-4">
                    {renderLink(lp)}
                  </div>
                )
              )}
            </div>
            <div className="inline-flex items-center pl-2">
              {mapSnsLinkProps({
                [SnsItem.Facebook]: (
                  <Text
                    color="$gray800"
                    className="flex items-center mx-1 md:mx-2"
                  >
                    <FacebookIcon size={28} />
                  </Text>
                ),
                [SnsItem.Instagram]: (
                  <Text
                    color="$gray800"
                    className="flex items-center mx-1 md:mx-2"
                  >
                    <InstagramIcon size={28} />
                  </Text>
                ),
              })
                .map(withEmptyName)
                .map(renderLink)}
            </div>
          </div>

          <div className="inline-flex items-center">
            <Switch
              className="mx-2 md:mx-4"
              checked={isDark}
              onChange={(e) => setTheme(e.target.checked ? 'dark' : 'light')}
              size="lg"
              icon={<MoonIcon filled />}
            />
          </div>

          <div className="flex md:hidden">
            <SideMenu
              renderLink={renderLink}
              links={[
                ...mapPageLinkProps(currentPath, pageLinkOptions, {
                  [PageItem.Home]: <HomeIcon size={20} fill="white" />,
                  [PageItem.About]: <SearchIcon size={20} fill="white" />,
                  [PageItem.Team]: <FaceIcon size={20} fill="white" />,
                  [PageItem.Cases]: <CategoryAltIcon size={20} fill="white" />,
                }),
                ...mapSnsLinkProps({
                  [SnsItem.Facebook]: <FacebookIcon size={20} fill="white" />,
                  [SnsItem.Instagram]: <InstagramIcon size={20} fill="white" />,
                }),
              ]}
            />
          </div>
        </Container>
      </nav>
    </header>
  )
}
