import { motion } from 'framer-motion'

const Path = (props: any) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="currentColor"
    strokeLinecap="round"
    {...props}
  />
)

type Props = {
  isOpen: boolean
  handleToggle: () => void
}

const MenuToggle = ({ isOpen, handleToggle }: Props) => {
  return (
    <motion.span
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      className="flex items-center "
    >
      <button
        onClick={handleToggle}
        className="flex items-center outline-none border-0 cursor-pointer bg-transparent pt-2"
      >
        <svg width="24" height="24" viewBox="0 0 24 24">
          <Path
            variants={{
              closed: { d: 'M 2 2.5 L 20 2.5' },
              open: { d: 'M 3 16.5 L 17 2.5' },
            }}
          />
          <Path
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 },
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            variants={{
              closed: { d: 'M 2 16.346 L 20 16.346' },
              open: { d: 'M 3 2.5 L 17 16.346' },
            }}
          />
        </svg>
      </button>
    </motion.span>
  )
}

export default MenuToggle
