import { useState } from 'react'
import { ThemeType } from '@nextui-org/react'
import Cookies from 'js-cookie'
import { Theme, lightTheme, darkTheme } from 'ui/theme'
import { SHARED_COOKIES_DOMAIN } from 'utils/cookies'

const COOKIE_THEME = 'theme'

const useTheme = () => {
  const [theme, setTheme] = useState(Cookies.get(COOKIE_THEME) || Theme.Light)

  const getTheme = (theme: string) => {
    if (theme === Theme.Dark) return darkTheme
    return lightTheme
  }

  const toggleTheme = (theme: ThemeType) => {
    Cookies.set(COOKIE_THEME, theme, { domain: SHARED_COOKIES_DOMAIN })
    setTheme(theme)
  }

  return {
    theme: getTheme(theme),
    setTheme: toggleTheme,
  }
}

export default useTheme
