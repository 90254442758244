import { ReactNode } from 'react'
import Header, { LinkProps, PageItem } from 'ui/Header'
import Footer from 'ui/Footer'
import { Link, Text, NextUIProvider } from '@nextui-org/react'
import { Link as GatsbyLink } from 'gatsby'
import { useLocation } from '@reach/router'
import useTheme from 'hooks/useTheme'

const renderLink = ({
  name,
  icon,
  key,
  href,
  active,
  external,
  targetBlank,
}: LinkProps) => {
  const color = active ? 'primary' : 'default'
  const content = (
    <>
      {icon && icon}
      {name && <Text color={color}>{name}</Text>}
    </>
  )

  if (external) {
    return (
      <Link
        key={key}
        href={href}
        {...(targetBlank && {
          target: '_blank',
          rel: 'noopener',
        })}
      >
        {content}
      </Link>
    )
  }

  // Links inside gatsby app
  return (
    <GatsbyLink key={key} to={href}>
      <Link
        {...(targetBlank && {
          target: '_blank',
          rel: 'noopener',
        })}
        as="span"
      >
        {content}
      </Link>
    </GatsbyLink>
  )
}

const Layout = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  const { theme, setTheme } = useTheme()

  return (
    <>
      <NextUIProvider theme={theme}>
        <Header
          setTheme={setTheme}
          renderLink={renderLink}
          currentPath={location.pathname}
          pageLinkOptions={{
            // Mark sites pages as external
            [PageItem.Home]: {
              origin: process.env.GATSBY_PUBLIC_SITES_ORIGIN ?? '',
            },
            [PageItem.About]: {
              origin: process.env.GATSBY_PUBLIC_SITES_ORIGIN ?? '',
            },
            [PageItem.Team]: {
              origin: process.env.GATSBY_PUBLIC_SITES_ORIGIN ?? '',
            },
          }}
        />
        <main>{children}</main>
        <Footer
          renderLink={renderLink}
          homeLinkOptions={{
            origin: process.env.GATSBY_PUBLIC_SITES_ORIGIN,
          }}
        />
      </NextUIProvider>
    </>
  )
}

export default Layout
