export interface ApplicationError {
  message: string
  status?: number
  code?: string
}

export const NotFoundError = (msg?: string): ApplicationError => {
  return {
    message: msg ?? errorMessages['404'],
    status: 404,
  }
}

export const InternalError = (msg?: string): ApplicationError => {
  return {
    message: msg ?? errorMessages['500'],
    status: 500,
  }
}

const errorMessages = {
  '404': '頁面不存在',
  '500': '系統內部錯誤',
}
