// 1. Import `createTheme`
import { createTheme } from '@nextui-org/react'

enum Theme {
  Dark = 'dark',
  Light = 'light',
}

// 2. Call `createTheme` and pass your custom values
const lightTheme = createTheme({
  type: 'light',
  //   theme: {
  //     colors: {...}, // optional
  //   }
})

const darkTheme = createTheme({
  type: 'dark',
  //   theme: {
  //     colors: {...}, // optional
  //   }
})

export { lightTheme, darkTheme, Theme }
